<template>
	<div class="colleagues">
		<div class="flex_end mb_15">
			<div class="flex_cen color fs_14 btn" @click="checkCompanyDialog">
				<img src="../../../assets/imgs/icon/invite-icon.png" class="invite-icon" alt="">
				邀请新企业
			</div>
		</div>
		<!-- 日期、推荐人、企业名称、信用代码、状态、佣金比例、佣金金额 -->
		<el-table :data="list" border style="width: 100%;">
			<el-table-column prop="create_time" label="日期"></el-table-column>
			<el-table-column label="推荐人" width="120px">
				<template #default="{ row }">
					<div class="flex_c">
						<el-avatar :src="row.avatar" :size="38" class="avatar mr_10" alt="头像">
							<img :src="row.gender == 1
								? $store.state.nanAvatar
								: $store.state.nvAvatar
								" />
						</el-avatar>
						<span>{{ row.realname }}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column prop="company_name" label="企业名称"></el-table-column>
			<el-table-column prop="creditcode" label="信用代码"></el-table-column>
			<el-table-column prop="status_text" label="状态">
			</el-table-column>
			<el-table-column prop="commission1_rate" label="佣金比例">
				<template #default="{ row }">
					<span v-if="row.commission1_rate">{{ row.commission1_rate }}%</span>
					<span v-else>-</span>
				</template>
			</el-table-column>
			<el-table-column prop="commission1_value" label="佣金金额">
				<template #default="{ row }">
					<span v-if="row.commission1_value">{{ row.commission1_value }}</span>
					<span v-else>-</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="180px" v-if="companyInfo.owner">
				<template #default="{ row }">
					<div class="flex_c">
						<div class="tag" v-if="row.commission1_value && row.commission1_status == 0"
							@click="handleClick(row.id)">
							转入CRC
						</div>
						<el-button type="primary" size="mini"
							v-if="row.commission1_value && row.commission1_status == 0"
							@click="hasTransferAccount(row.id)">提现至公账</el-button>
						<div class="tag" v-if="!row.commission1_value">
							无操作
						</div>
						<!-- <div class="tag" v-if="row.mobile == userInfo.mobile">自己</div>
						<div class="tag" v-else-if="row.status === 2">已离职</div>
						<div class="tag btn" v-if="row.mobile != userInfo.mobile && row.status === 1 && !row.owner"
							@click="handleClick(row.id, row.realname, 'quit')">离职</div>
						<el-button type="primary" size="mini"
							v-if="row.mobile != userInfo.mobile && row.status === 1 && !row.owner"
							@click="handleClick(row.id, row.realname, 'owner')">转让创建者</el-button> -->
					</div>
				</template>
			</el-table-column>
		</el-table>

		<el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next"
			:total="total" @current-change="load" v-if="list.length != 0">
		</el-pagination>
		<share-dialog ref="shareDialog"></share-dialog>
		<check-company-dialog ref="checkCompanyDialog" @share="shareDialog"></check-company-dialog>
	</div>
</template>

<script>
// import { mapGetters } from "vuex", l_setItem
import { l_getItem } from "@/common/cache"

import shareDialog from './share-company-dialog'
import checkCompanyDialog from './check-company-dialog'
export default {
	components: { shareDialog, checkCompanyDialog },
	data() {
		return {
			list: [],
			limit: 10,
			page: 1,
			total: 0,
			finish: false
		}
	},
	created() {
		this.init()
		this.companyInfo = l_getItem('COMPANY')
		this.userInfo = l_getItem('USERINFO')
	},
	// computed: {
	// 	...mapGetters({
	// 		userInfo: "getUserInfo",
	// 		companyInfo: "getCompanyInfo"
	// 	})
	// },
	methods: {
		init() {
			this.$axios({
				url: `/ent/v4/company/recommend/list`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					status: 1
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data.list || []
					// this.list.push({
					// 	avatar: "https://zlh.zhiluinfo.com//uploads/user/avatar/20210520/1ab00b5b7d1e0d219cff95dc7e2825a0.jpg",
					// 	create_time: "2021-01-16",
					// 	dept_name: "月朋",
					// 	gender: 1,
					// 	id: 7,
					// 	invite_name: "-",
					// 	job_name: "忆忆",
					// 	member_crc: 1,
					// 	mobile: "13632883610",
					// 	owner: 0,
					// 	realname: "周忠南",
					// 	status: 2,
					// 	status_text: "离职"
					// })
					this.total = res.data.total || 0
					this.finish = this.list.length === 0
				} else {
					this.finish = true
				}
			})
		},
		handleClick(id) {
			let title = `请确认是否转入CRC`
			this.$confirm(title, "操作提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$axios({
					url: `/ent/v4/company/transfer/crc/${id}`,
					method: "POST",
					shake: true,
					hideLoading: true
				}).then(res => {
					if (res.code === 200) this.$message.success('操作成功')
					this.init()
				})
			})
		},
		hasTransferAccount(id) {
			let title = '申请已受理，等待服务人员联系'
			this.$confirm(title, "操作提示", {
				confirmButtonText: "确定",
				cancelButtonText: "取消",
				type: "warning"
			}).then(() => {
				this.$axios({
					url: `/ent/v4/company/transfer/account/${id}`,
					method: "POST",
					shake: true,
					hideLoading: true
				}).then(res => {
					if (res.code === 200) this.$message.success('操作成功')
					this.init()
				})
			})
		},
		// handleClick(id, realname, action) {
		// 	let title = action === 'quit' ? `确认将【${realname}】从本企业离职` : `确认将创建者转让给【${realname}】？`
		// 	this.$confirm(title, "操作提示", {
		// 		confirmButtonText: "确定",
		// 		cancelButtonText: "取消",
		// 		type: "warning"
		// 	}).then(() => {
		// 		this.$axios({
		// 			url: `/ent/v3/usercenter/company/member/set/${id}`,
		// 			method: "POST",
		// 			data: {
		// 				action
		// 			},
		// 			shake: true,
		// 			hideLoading: true
		// 		}).then(res => {
		// 			if (res.code === 200) {
		// 				if (action === 'owner') {
		// 					this.companyInfo.owner = 0
		// 					l_setItem('COMPANY', this.companyInfo)
		// 				}
		// 				this.$message.success('操作成功')
		// 				this.init()
		// 			}
		// 		})
		// 	})
		// },
		load(page) {
			this.page = page
			this.init()
		},
		checkCompanyDialog() {
			this.$refs.checkCompanyDialog.show()
		},
		shareDialog() {
			this.$refs.shareDialog.getLink()
		}
	}
}
</script>

<style lang="less" scoped>
.table {
	min-height: 441px;
	width: 100%;
}

.invite-icon {
	width: 14px;
	margin-right: 7px;
}

.page {
	display: table;
	margin: 0 auto;
	margin-top: 20px;
}

.tag {
	padding: 7px 0;
	width: 60px;
	border-radius: 3px;
	font-size: 12px;
	border: 1px solid #DCDFE6;
	line-height: 1;
	margin-right: 10px;
	cursor: pointer;
}

.colleagues {
	/deep/.el-table thead tr th {
		background: #ededed !important;

		/deep/.cell {
			font-size: 14px;
			font-weight: bold;
			color: #333;
			text-align: center;
		}
	}

	/deep/.cell {
		text-align: center;
	}

	.avatar {
		width: 38px;
		height: 38px;
		border-radius: 100%;
	}

	/deep/.el-badge__content--primary {
		background-color: #ff4e4e !important;
	}
}
</style>
