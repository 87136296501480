<template>
	<div class="companyCrc">
		<div class="table">
			<el-table :data="list" border :row-style="rowStyle">
				<el-table-column prop="message" label="操作"> </el-table-column>
				<el-table-column prop="op_name" label="操作人"> </el-table-column>
				<el-table-column prop="create_time" label="操作时间"> </el-table-column>
				<el-table-column prop="number" label="CRC变化"> </el-table-column>
				<!-- <el-table-column prop="final_amount" label="剩余CRC"> </el-table-column> -->
				<template #empty>
					<div>
						<p class="loading_" v-if="!finish">
							<i class="el-icon-loading fs_20"></i> 努力加载中...
						</p>
						<p class="loading_" v-else>暂无数据</p>
					</div>
				</template>
			</el-table>
		</div>
		<el-pagination
			class="page"
			background
			:page-size="limit"
			:current-page="page"
			layout="prev, pager, next"
			:total="total"
			@current-change="load"
		>
		</el-pagination>
	</div>
</template>

<script>
export default {
	name: "",
	data() {
		return {
			list: [],
			limit: 10,
			page: 1,
			total: 0,
			finish: false
		}
	},
	props: {
		type: {
			default: '',
			type: String
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/usercenter/company/${this.type}`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data.list
					this.total = res.data.total
					this.finish = this.list.length === 0
				} else {
					this.finish = true
				}
			})
		},
		load(page) {
			this.page = page
			this.init()
		}
	}
}
</script>

<style lang="less" scoped>
.companyCrc {
	.table {
		min-height: 441px;
		width: 100%;
	}
	.page {
		display: table;
		margin: 0 auto;
		margin-top: 20px;
	}
	/deep/.el-table thead tr th {
		background: #ededed !important;
		/deep/.cell {
			font-size: 14px;
			font-weight: bold;
			color: #333;
			text-align: center;
		}
	}
	/deep/.cell {
		text-align: center;
	}
}
</style>
