<template>
	<el-dialog v-model="dialogVisible" :lock-scroll="true" :close-on-click-modal="false" width="700px" top="20vh">
		<template v-slot:title>
			<div class="flex_c">
				<p class="fs_18 bold">邀请新企业</p>
			</div>
		</template>
		<div class="content">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<el-form-item label="企业名称" prop="company">
					<el-input placeholder="请输入企业名称" class="flex_c" type="text" v-model.trim="ruleForm.company"
						@input="search"></el-input>
					<div class="fs_14 color_999" v-if="notCompany == 1"><i class="el-icon-error fs_16 mr_5"
							style="color:#ff4e4e;"></i>抱歉该企业查询不到</div>
					<div class="flex" v-else>
						<div class="fs_14 color_333" v-show="ruleForm.creditcode">信用代码 {{ ruleForm.creditcode }}</div>
					</div>
				</el-form-item>
				<!-- 远程搜索列表 -->
				<el-collapse-transition>
					<div class="states" v-if="showStates">
						<p class="padding_15 bgf fs_14 color_333 item bottom_link pointer"
							v-for="(item, index) in companyList" :key="index" @click="selectCompany(item)">
							{{ item.name }}
						</p>
					</div>
				</el-collapse-transition>

				<div class="flex_cen">
					<el-button type="primary" class="fs_18" :class="{ disabled: !ruleForm.creditcode }"
						:disabled="!ruleForm.creditcode" @click="submit">确认</el-button>
				</div>
			</el-form>
		</div>
		<template v-slot:footer>

		</template>
	</el-dialog>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			dialogVisible: false,
			showStates: false, // 远程搜索选择框
			companyList: [], // 企业列表
			inviteId: '',
			title: '',
			link: '',
			loading: false,
			hour: 0,
			ruleForm: {
				company: '',
				creditcode: ''
			},
			rules: {
				company: [
					{ required: true, message: '请输入企业名称', trigger: 'change' }
				]
			}
		}
	},

	methods: {
		show() {
			this.dialogVisible = true
		},
		/* 远程搜索 */
		search() {
			if (!this.ruleForm.company) {
				this.ruleForm.creditcode = ''
				return
			}
			this.notCompany = 0
			this.ruleForm.creditcode = ''
			this.btnText = '校 验'
			this.$axios({
				url: '/ent/v3/usercenter/company/search',
				method: 'GET',
				data: {
					keyword: this.ruleForm.company
				},
				shake: true,
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.companyList = res.data
					this.showStates = true
				}
			})
		},
		/* 选择企业 */
		selectCompany(item) {
			this.showStates = false
			this.ruleForm.company = item.name
			this.ruleForm.creditcode = item.creditCode
		},
		submit() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: `/ent/v4/company/recommend`,
						// url: `/ent/v3/usercenter/company/members/invite_link_send/${this.inviteId}`,
						method: 'POST',
						data: {
							company_name: this.ruleForm.company,
							creditcode: this.ruleForm.creditcode
						},
						hideLoading: true,
						shake: true
					}).then(res => {
						this.loading = false
						this.$message.success(res.msg)
						if (res.code === 200) {
							this.dialogVisible = false
							this.$emit('share')
						}
					})
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
/deep/.el-dialog__header {
	padding: 20px;
}

/deep/.el-dialog__body {
	padding: 0;
}

.content {
	border-top: 1px solid #ddd;
	// height: 168px;
	padding: 30px 30px 0;

	.in-icon {
		width: 46px;
		margin-right: 16px;
	}

	/deep/.el-input__inner {
		height: 44px;
		background: #f9f9f9;
		padding-left: 20px;
		font-size: 14px;
	}

	/deep/.el-button {
		width: 160px;
		height: 44px;
		line-height: 28px;
		margin-left: 20px;
		border-color: #ddd;
		padding: 9px 0;

		&:hover {
			border-color: #5ab0ff;
		}
	}
}

.el-form {
	position: relative;

	.states {
		position: absolute;
		width: 100%;
		height: auto;
		left: 0;
		top: 86px;
		z-index: 2;
		box-shadow: 0px 3px 20px rgba(158, 158, 158, 0.16);
		max-height: 294px;
		overflow-y: scroll;

		.item {
			transition: color .2s ease;

			&:hover {
				color: #1890ff;
				transition: color .2s ease;
			}
		}
	}

	/deep/.el-input__inner {
		margin-bottom: 0;
	}

	// .el-button {
	// 	width: 200px;
	// 	height: 52px;
	// 	margin-top: 80px;
	// }

	.in-icon {
		width: 18px;
		height: 18px;
		margin-right: 10px;
	}

	.admin-list {
		padding-left: 30px;
	}
}
</style>