<template>
	<div class="vip-order">
		<el-table :data="list" border style="width: 100%;">
			<el-table-column prop="type" label="订单类型"></el-table-column>
			<el-table-column prop="op_name" label="操作人"></el-table-column>
			<el-table-column prop="create_time" label="操作时间"></el-table-column>
			<el-table-column prop="order_total" label="订单金额">
                <template #default="{ row }">
                    <div class="flex_cen" >
                        {{ row.order_total }} 元
                    </div>
                </template>
            </el-table-column>
			<el-table-column prop="validity_days" label="会员时长"></el-table-column>
			<el-table-column prop="crc_num" label="赠送CRC数量"></el-table-column>
			<el-table-column prop="payment_method" label="支付方式"></el-table-column>
            <el-table-column prop="payment_status" label="支付状态"></el-table-column>
			<el-table-column label="操作" width="120px">
                <template #default="{ row }">
                    <div class="flex_c" >
                        <el-button type="primary" size="mini" v-if="row.payment_status==='未支付'" @click="$store.commit('set_showVipModal', true)">去支付</el-button>
                        <el-button type="info" size="mini" v-else-if="row.invoice_status" @click="handleClick(row.id, row.invoice_info, row.invoice_status)">开票详情</el-button>
                        <el-button type="primary" size="mini" v-else @click="handleClick(row.id, row.invoice_info, row.invoice_status)">申请开票</el-button>
                    </div>
                </template>
            </el-table-column>
			<template #empty>
                <div>
                    <p class="loading_" v-if="!finish">
                        <i class="el-icon-loading fs_20"></i> 努力加载中...
                    </p>
                    <p class="loading_" v-else>暂无数据</p>
                </div>
            </template>
		</el-table>
		<el-pagination class="page" background :page-size="limit" :current-page="page" layout="prev, pager, next" :total="total" @current-change="load" v-if="list.length!=0">
		</el-pagination>
		<share-dialog ref="shareDialog"></share-dialog>

        <pay-vip-dialog @cb="init"></pay-vip-dialog>
        <invoice-dialog @cb="init" :orderId="orderId" :shipAddress="true" :buttonText="buttonText" ref="invoiceDialog"></invoice-dialog>
	</div>
</template>

<script>
import invoiceDialog from '@/components/invoice-dialog'
import shareDialog from './share-dialog'
export default {
	components: { shareDialog, invoiceDialog },
	data() {
		return {
			list: [],
			orderId: 0,
			limit: 10,
			page: 1,
			total: 0,
			finish: false,
			buttonText: '确认开票'
		}
	},
	created() {
		this.init()
	},
	methods: {
		init() {
			this.$axios({
				url: `/ent/v3/usercenter/company/order`,
				method: "GET",
				data: {
					page: this.page,
					limit: this.limit,
					status: 1
				},
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.list = res.data.list || []
					this.total = res.data.total || 0
					this.finish = this.list.length === 0
				} else {
					this.finish = true
				}
			})
		},
		handleClick(id, invoice_info, invoice_status) {
			this.orderId = id
			this.$refs.invoiceDialog.dialogVisible = true
			if(invoice_info) {
				if(invoice_info.invoice_type == '0') {
					invoice_info.invoice_type = '增值税普通发票'
				}else {
					invoice_info.invoice_type = '增值税专用发票'
				}
				this.$refs.invoiceDialog.ruleForms = invoice_info
			}
			if(invoice_status) {
				this.buttonText = '保存'
				return
			}
			this.buttonText = '确认开票'
			this.$refs.invoiceDialog.getInvoice()
		},
		load(page) {
			this.page = page
			this.init()
		},
		shareDialog() {
			this.$refs.shareDialog.getLink()
		}
	}
}
</script>

<style lang="less" scoped>
.table {
	min-height: 441px;
	width: 100%;
}
.invite-icon {
	width: 14px;
	margin-right: 7px;
}
.page {
	display: table;
	margin: 0 auto;
	margin-top: 20px;
}

.vip-order {
	/deep/.el-table thead tr th {
		background: #ededed !important;
		/deep/.cell {
			font-size: 14px;
			font-weight: bold;
			color: #333;
			text-align: center;
		}
	}
	/deep/.cell {
		text-align: center;
	}

	/deep/.el-badge__content--primary {
		background-color: #ff4e4e !important;
	}
}
</style>
