<!--
 * @Author: WeChat: TashiDelek668
 * @Date: 2021-01-21 10:04:34
 * @LastEditors: TashiDelek
 * @LastEditTime: 2021-01-21 10:29:43
 * @FilePath: \hr_admin_2.0\src\views\user\components\share-dialog.vue
-->
<template>
    <el-dialog v-model="dialogVisible" :lock-scroll="true" :before-close="close" :close-on-click-modal="false" width="460px">
        <template v-slot:title>
            <div class="flex_c">
                <p class="fs_18 bold">企业CRC转赠</p>
            </div>
        </template>
        <div class="content">
            <div class="flex_cen">
                <span class="fs_16 color_333">当前可用CRC金额</span>
                <span class="fs_16 price_color bold">{{ balance }}</span>
            </div>
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
                <el-form-item label="转赠企业名称" prop="company">
                    <el-input placeholder="请输入转赠企业名称" type="text" v-model.trim="ruleForm.company" @input="search">
                    </el-input>
                    <p class="fs_13 color_999" v-show="companyInfo.creditcode">法人代表：{{ companyInfo.represent }} &nbsp;&nbsp;&nbsp;&nbsp; 信用代码：{{ companyInfo.creditcode }} </p>
                </el-form-item>
                <!-- 远程搜索列表 -->
                <el-collapse-transition>
                    <div class="states" v-if="showStates">
                        <p class="padding_15 bgf fs_14 sub_color" v-if="companyList.length==0">平台内未找到该企业</p>
                        <p class="padding_15 bgf fs_14 color_333 item bottom_link pointer" v-for="(item, index) in companyList" :key="index" @click="selectCompany(item)">{{ item.company_name }}</p>
                    </div>
                </el-collapse-transition>
                <el-form-item label="本次转赠CRC金额" prop="number">
                    <el-input placeholder="请输入转赠CRC金额" type="text" v-model.trim="ruleForm.number" @input="ruleForm.number=ruleForm.number.replace(/\D/g, '')">
                    </el-input>
                </el-form-item>
                <el-form-item label="短信验证码" prop="captcha">
                    <el-input :placeholder="placeholder" maxlength="6" type="number" v-model.trim="ruleForm.captcha" @keyup.enter="submit">
                        <template #suffix>
                            <el-button type="text" class="btn" v-if="!sandSuccess" :loading="sandLoading" @click="sendCode">获取验证码</el-button>
                            <el-button type="text" class="btn" v-else>{{sandTime}}s后重新获取</el-button>
                        </template>
                    </el-input>
                </el-form-item>
                <div class="flex_cen">
                    <el-button type="primary" class="fs_14 givBtn" :class="{disable: !ruleForm.creditcode || !ruleForm.number || !ruleForm.captcha}" @click="submit">确认转赠</el-button>
                </div>
            </el-form>
        </div>
    </el-dialog>
</template>

<script>
import { l_getItem } from "@/common/cache"

export default {
	name: '',
	data() {
		return {
			interval: null,
			dialogVisible: false,
			loading: false,
			sandSuccess: false,
			sandLoading: false,
			sandTime: 60,
			balance: 0,
			placeholder: '请输入验证码',
			showStates: false, // 远程搜索显示/隐藏
			companyList: [], // 企业列表
			companyInfo: {
				creditcode: '', // 企业代码
				represent: '' // 法人
			},
			userInfo: {},
			ruleForm: {
				company: '',
				creditcode: '',
				number: '',
				captcha: ''
			},
			rules: {
				company: [
					{ required: true, message: '请输入转赠企业名称', trigger: 'change' }
				],
				captcha: [],
				number: [
					{ required: true, message: '请输入转赠CRC金额', trigger: 'change' }
				]
			}
		}
	},
	created() {
		this.userInfo = l_getItem('USERINFO')
		let mobile = this.userInfo.mobile
		this.placeholder = '请输入' + mobile.substring(0, 3) + '****' + mobile.substring(7, 11) + '的验证码'
	},
	methods: {
		/* 远程搜索 */
		search() {
			if (!this.ruleForm.company) {
				this.companyInfo.creditcode = ''
				this.companyList = []
				this.showStates = false
				return
			}
			this.companyInfo.creditcode = ''
			this.$axios({
				url: '/ent/v3/usercenter/company/search',
				method: 'GET',
				data: {
					keyword: this.ruleForm.company,
					type: 'company'
				},
				shake: true,
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					this.companyList = res.data
					this.showStates = true
				}
			})
		},
		/* 选择企业 */
		selectCompany(item) {
			this.showStates = false
			this.ruleForm.company = item.company_name
			this.ruleForm.creditcode = item.creditcode
			this.companyInfo.creditcode = item.creditcode
			this.companyInfo.represent = item.represent
		},
		close() {
			this.$refs.ruleForm.resetFields()
			this.showStates = false
			this.companyInfo.creditcode = ''
			if(this.interval) {
				clearInterval(this.interval)
				this.sandSuccess = false
				this.sandTime = 60
			}
			this.dialogVisible = false
		},
		submit() {
			if (!this.ruleForm.creditcode || !this.ruleForm.number || !this.ruleForm.captcha) {
				return
			}
			this.rules.captcha = [ { required: true, message: '请输入验证码', trigger: 'change' } ]
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: `/ent/v3/usercenter/company/transfer`,
						method: 'POST',
						data: this.ruleForm,
						hideLoading: true,
						shake: true
					}).then(res => {
						this.loading = false
						if (res.code === 200) {
							this.balance = this.balance - this.ruleForm.number
							this.$parent.company.balance = this.balance
							this.$message.success(res.msg)
							this.$refs.ruleForm.resetFields()
							this.companyInfo.creditcode = ''
							this.companyInfo.represent = ''
							this.dialogVisible = false
						}
					})
				}
			})
		},
		sendCode() {
			this.rules.captcha = []
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.sandLoading = true
					this.$axios({
						url: '/api/v2/account/verify',
						method: 'POST',
						data: {
							phone: this.userInfo.mobile,
							type: 'verify'
						},
						no_carryToken: true,
						hideLoading: true,
						shake: true
					}).then(res => {
						setTimeout(() => {
							this.sandLoading = false
						}, 900)
						if (res.code === 200) {
							this.$message.success(res.msg)
							this.sandSuccess = true
							this.countTime()
						}
					})
				}
			})
		},
		countTime() {
			var currentTime = 60
			this.interval = setInterval(() => {
				this.sandTime = currentTime--
				if (currentTime < 0) {
					clearInterval(this.interval) // 清除计时器
					this.rules.captcha = []
					this.sandSuccess = false
					this.sandTime = 60
				}
			}, 1000)
		}
	}
}
</script>

<style lang='less' scoped>
@import url("../../../assets/style/login.less");
/deep/.el-dialog__header {
    padding: 20px;
}
/deep/.el-dialog__body {
    padding: 0;
}
.disabled {
    background: #ddd !important;
    border: none !important;
}
.content {
    border-top: 1px solid #ddd;
    padding: 30px 58px;
    .price_color {
        line-height: 21px;
        margin-left: 2px;
    }
    .el-form {
        margin-top: 24px;
        position: relative;
        .states {
            position: absolute;
            width: 100%;
            height: auto;
            left: 0;
            top: 80px;
            z-index: 2;
            box-shadow: 0px 3px 20px rgba(158, 158, 158, 0.16);
            max-height: 294px;
            overflow-y: scroll;
            .item {
                transition: color 0.2s ease;
                &:hover {
                    color: #1890ff;
                    transition: color 0.2s ease;
                }
            }
        }
    }
    .el-button {
        color: #333;
        line-height: 26px;
        height: 44px;
    }
    /deep/.el-input__inner {
        padding-left: 20px;
        height: 44px;
        margin-bottom: 0;
    }
    /deep/.el-form-item__label {
        font-size: 16px;
        color: #333;
    }
    .givBtn {
        width: 162px;
        height: 42px;
        background: #1890ff;
        border-radius: 2px;
        color: #fff;
        margin: 12px auto 0;
        &.disable {
            background: #dedede;
            border: none;
        }
    }
}
</style>