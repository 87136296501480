<template>
	<div class='invoice'>
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-position="top" class="form">
			<div class="fs_20 bold color_333 title mb_15">发票信息</div>
			<el-row :gutter="20">
				<el-col :span="6">
					<el-form-item label="企业发票抬头" prop="company_name">
						<el-input type="text" placeholder="请填写企业发票抬头" readonly v-model.trim="ruleForm.company_name"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="6">
					<el-form-item label="税务登记号" prop="creditcode">
						<el-input type="text" placeholder="请填写税务登记号" readonly v-model.trim="ruleForm.creditcode"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="发票类型" prop="invoice_type">
						<el-select v-model="ruleForm.invoice_type" placeholder="请选择发票类型" style="width:100%" @change="changeInvoice">
							<!-- <el-option label="请选择发票类型" value="-1"></el-option> -->
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="基本开户账号" prop="bank_account">
						<el-input type="number" placeholder="请填写基本开户账号" v-model.trim="ruleForm.bank_account"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="开户银行名称" prop="bank_name">
						<el-input type="text" placeholder="请填写开户银行名称" v-model.trim="ruleForm.bank_name" @input="ruleForm.bank_name=textFilter(ruleForm.bank_name)"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="注册场所地址" prop="address">
						<el-input type="text" placeholder="请填写注册场所地址" v-model.trim="ruleForm.address" @input="ruleForm.address=ruleForm.address.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="注册固定电话" prop="phone">
						<el-input type="number" placeholder="请填写注册固定电话" v-model.trim="ruleForm.phone"></el-input>
					</el-form-item>
				</el-col>
			</el-row><br><br>
			<div class="fs_20 bold color_333 title mb_15">收件信息</div>
			<el-row :gutter="20">
				<el-col :span="12">
					<el-form-item label="收件人" prop="ship_name">
						<el-input type="text" placeholder="请填写收件人" v-model.trim="ruleForm.ship_name" @input="ruleForm.ship_name=ruleForm.ship_name.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
					</el-form-item>
				</el-col>
				<el-col :span="12">
					<el-form-item label="收件人电话" prop="ship_phone">
						<el-input type="number" placeholder="请填写收件人电话" v-model.trim="ruleForm.ship_phone"></el-input>
					</el-form-item>
				</el-col>
                <el-col :span="24">
					<el-form-item label="收件地址" prop="ship_address">
						<el-input type="text" placeholder="请填写收件地址" v-model.trim="ruleForm.ship_address" @input="ruleForm.ship_address=ruleForm.ship_address.replace(/[^\u4E00-\u9FA5A-Za-z0-9]/g, '')"></el-input>
					</el-form-item>
				</el-col>
			</el-row>
			<el-form-item class="flex_cen">
				<el-button type="primary" :loading="loading" @click="submitForm('ruleForm')">确认修改</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
import { isPhone } from '@/common/validate'
export default {
	data() {
		var checkMobile = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请填写收件人电话'))
			} else if (!isPhone(value)) {
				callback(new Error('请填写正确的收件人电话'))
			} else {
				callback()
			}
		}
		// var checkInvoice = (rule, value, callback) => {
		// 	if (value === '') {
		// 		callback(new Error('请选择发票类型'))
		// 	} else {
		// 		callback()
		// 	}
		// }
		// var checkRate = (rule, value, callback) => {
		// 	if (value == '') {
		// 		callback(new Error('请填写税点'))
		// 	} else if (value>=100) {
		// 		callback(new Error('税点不能大于 100%'))
		// 	} else if (value.indexOf('-')>-1) {
		// 		callback(new Error('请输入正数值'))
		// 	} else if (!isNum(value)) {
		// 		callback(new Error('小数点后保留两位小数'))
		// 	} else {
		// 		callback()
		// 	}
		// }
		return {
			loading: false,
			ruleForm: {
				company_name: "",
				creditcode: "",
				invoice_type: "",
				tax_rate: "",
				bank_name: "",
				bank_account: "",
				address: "",
				phone: "",
				ship_name: "",
				ship_address: "",
				ship_phone: ""
			},
			options: [  {
				value: 0,
				label: '增值税普通发票'
			}, {
				value: 1,
				label: '增值税专用发票'
			} ],
			rules: {
				company_name: [
					{ required: true, message: '请填写企业发票抬头', trigger: 'change' }
				],
				creditcode: [
					{ required: true, message: '请填写税务登记号', trigger: 'change' }
				],
				invoice_type: [
					// { required: true, validator: checkInvoice, trigger: 'change' }
					{ required: true, message: '请选择发票类型', trigger: 'change' }
				],
				bank_name: [/* 动态验证 */],
				bank_account: [/* 动态验证 */],
				address: [/* 动态验证 */],
				phone: [/* 动态验证 */],
				ship_name: [
					{ required: true, message: '请填写收件人', trigger: 'change' }
				],
				ship_address: [
					{ required: true, message: '请填写收件地址', trigger: 'change' }
				],
				ship_phone: [
					{ required: true, validator: checkMobile, trigger: 'blur' }
				]
				// tax_rate: [
				// 	{ required: true, validator: checkRate, trigger: 'change' }
				// ]
			}
		}
	},
	created() {
		this.getDetail()
	},
	methods: {
		getDetail() {
			this.$axios({
				url: '/ent/v3/usercenter/company/invoice',
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					this.ruleForm = res.data.invoice
					if(this.ruleForm.invoice_type===false) {
						this.ruleForm.invoice_type = ''
					}
					this.setRules(this.ruleForm.invoice_type)
				}
			})
		},
		changeInvoice(e) {
			this.setRules(e)
			this.$refs.ruleForm.clearValidate()
		},
		// 必填项切换
		setRules(e) {
			if(e === 1) {
				this.rules.bank_account.push({ required: true, message: '请填写基本开户账号', trigger: 'change' })
				this.rules.bank_name.push({ required: true, message: '请填写开户银行名称', trigger: 'change' })
				this.rules.address.push({ required: true, message: '请填写注册场所地址', trigger: 'change' })
				this.rules.phone.push({ required: true, message: '请填写注册固定电话', trigger: 'change' })
			}else {
				this.rules.bank_account = []
				this.rules.bank_name = []
				this.rules.address = []
				this.rules.phone = []
			}
		},
		submitForm(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					// if(this.ruleForm.invoice_type == -1) {
					// 	this.ruleForm.invoice_type = ''
					// 	return
					// }
					this.loading = true
					this.$axios({
						url: '/ent/v3/usercenter/company/invoice',
						method: 'POST',
						data: this.ruleForm,
						shake: true,
						hideLoading: true
					}).then(res=>{
						setTimeout(() => {
							this.loading = false
						}, 900)
						if(res.code === 200) {
							this.$message.success("保存成功")
						}
					})
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
/deep/.form {
	.el-form-item__label {
		padding: 0 !important;
	}
	.el-input__inner {
		height: 44px;
		background-color: #f9f9f9;
	}
	.el-form-item__label {
		font-size: 16px;
	}
	.title {
		padding-bottom: 20px;
		border-bottom: 1px dashed #ededed;
	}
	.el-button {
		width: 200px;
		height: 52px;
		margin-top: 60px;
		font-size: 18px;
	}
}
</style>