<template>
	<div class="info">
		<div class="title">基本信息</div>
		<div class="fs_16 bold color_333" style="margin-top:30px;">
			{{ companyInfo.company_name }}
		</div>
		<el-row :gutter="0">
			<el-col :span="24">
				<p class="fs_14 color_666 mt_20">
					<span class="mr_10">信用代码：</span>
					<span>{{ companyInfo.creditcode }}</span>
				</p>
				<p class="fs_14 color_666 mt_10">
					<span class="mr_10">人员规模：</span>
					<span>{{ companyInfo.staff_num_range }}</span>
				</p>
				<p class="fs_14 color_666 mt_10 flex_start">
					<span class="mr_10">注册地址：</span>
					<span class="flex_1">
						{{ companyInfo.address }}
					</span>
				</p>
				<p class="fs_14 color_666 mt_10">
					<span class="mr_10">法人代表：</span>
					<span>{{ companyInfo.represent }}</span>
				</p>
			</el-col>
			<el-col :span="7" v-if="false">
				<p class="fs_14 color_666 mt_20">
					<span class="mr_10">所属行业：</span>
					<span>商务服务业</span>(接口缺少字段)
				</p>
				<p class="fs_14 color_666 mt_10">
					<span class="mr_10">注册资本：</span>
					<span>50万人民币</span>(接口缺少字段)
				</p>
			</el-col>
			<!-- <el-col :span="7" v-if="false">
				<p class="fs_14 color_666 mt_20">
					<span class="mr_10">企业类型：</span>
					<span>商务服务业</span>(接口缺少字段)
				</p>
				<p class="fs_14 color_666 mt_10 flex_start">
					<span class="mr_10">公司官网：</span>
					<a class="flex_1 color_666" target="_black" href="www.zhiluinfo.com">
						www.zhiluinfo.com(接口缺少字段)
					</a>
				</p>
			</el-col> -->
		</el-row>

		<div style="margin-top:50px;">
			<div class="title">工作环境</div>
			<div class="mt_20 img-list">
				<el-upload
					list-type="picture-card"
					:limit="10"
					:action="`${baseApi}/api/v2/upload/image?filename=file&type=company`"
					:headers="headers"
					:file-list="fileList"
					accept=".jpg,.jpeg,.png"
					:class="{ disabled: companyInfo.pics_urls && companyInfo.pics_urls.length >= 10 }"
					:on-preview="handlePreview"
					:on-remove="handleRemove"
					:on-success="handleSuccess"
				>
					<el-image
						:src="require('@/assets/imgs/icon/img-icon.png')"
						style="width:24px;height:24px;"
						alt=""
						lazy="true"
					></el-image>
				</el-upload>
			</div>
			<el-dialog v-model="dialogVisible" width="25vw" top="8vh">
				<img width="100%" :src="dialogImageUrl" alt="" />
			</el-dialog>
		</div>
		<el-button class="submit fs_18" :loading="loading" type="primary" @click="submit">
			确认修改
		</el-button>
	</div>
</template>

<script>
import { mapGetters } from "vuex"
import { removeToken, getToken } from "@/common/cache"
export default {
	data() {
		return {
			loading: false,
			img_list: [],
			dialogVisible: false,
			disabled: false,
			baseApi: process.env.VUE_APP_API,
			headers: {
				Authorization: "Bearer " + getToken()
			}
		}
	},
	computed: {
		...mapGetters({
			companyInfo: "getCompanyInfo"
		}),
		fileList: function() {
			var list = []
			if (this.companyInfo.pics_urls) {
				this.companyInfo.pics_urls.forEach((item, index) => {
					list = list.concat({ url: item, name: index })
				})
			}
			return list
		}
	},
	methods: {
		handleRemove(file) {
			this.companyInfo.pics.splice(file.name, 1)
			this.companyInfo.pics_urls.splice(file.name, 1)
		},
		handlePreview(file) {
			this.dialogImageUrl = file.url
			this.dialogVisible = true
		},
		handleSuccess(res) {
			if (res.code !== 200) {
				if (res.code === 410000) {
					this.$message.error("请登录")
					removeToken()
					this.$router.push("/login")
				} else {
					this.$message.error(res.msg)
				}
			} else {
				//提交链接
				this.companyInfo.pics = this.companyInfo.pics.concat(res.data.file_path)
				this.companyInfo.pics_urls = this.companyInfo.pics_urls.concat(res.data.url)
			}
		},
		submit() {
			if (this.companyInfo.pics.length == 0) {
				this.$message.error("请上传图片！")
				return
			}
			this.loading = true
			this.$axios({
				url: "/ent/v3/usercenter/company/info",
				method: "POST",
				data: {
					pics: this.companyInfo.pics
				},
				shake: true,
				hideLoading: true
			}).then(res => {
				setTimeout(() => {
					this.loading = false
				}, 900)
				if (res.code === 200) {
					this.$message.success("保存成功")
				}
			})
		}
	}
}
</script>

<style lang="less" scoped>
.submit {
	margin: 0 auto;
	width: 200px;
	line-height: 26px;
	height: 52px;
	display: block;
	margin-top: 50px;
	border-radius: 2px;
}
/deep/.img-list {
	margin-bottom: 20px;
	.el-upload-list {
		.el-upload-list__item {
			display: inline-block;
		}
	}
	.el-upload {
		display: inline-flex;
	}
}
.info {
	.title {
		font-size: 18px;
		font-weight: bold;
		color: #333;
		padding-left: 10px;
		border-left: 2px solid #1890ff;
		line-height: 18px;
	}
}
/deep/.disabled {
	.el-upload {
		display: none;
	}
}
/deep/.el-upload-list--picture-card .el-upload-list__item,
/deep/.el-upload {
	display: flex;
	align-items: center;
	justify-content: center;
}
</style>
