<template>
	<div class="contain">
		<!-- <img src="../../assets/imgs/company-page-bg.png" class="page" alt=""> -->
		<div class="z1">
			<!-- <searchBar :searchInput="false"></searchBar> -->
			<div class="crc-info flex">
				<div class="flex_cen left">
					<router-link to="/strategic" class="chart-btn fs_14 txt btn flex_cen" v-if="company.is_partner">
						战略伙伴
					</router-link>
					<div class="flex_c">
						<el-avatar :src="company.logo" :size="80" fit="contain" class="logo" alt="LOGO">
							<img :src="$store.state.baseLogo" />
						</el-avatar>
						<div class="flex_1">
							<div class="">
								<span class="fs_16 bold">{{ company.company_name }}</span>
								<img src="@/assets/imgs/icon/yrzqy-icon.png" class="auth" alt="已认证" />
							</div>
							<div class="flex_c">
								<p class="vip-tag fs_12 flex_cen btn" v-if="!COMPANY.company_lv"
									@click="$store.commit('set_showVipModal', true)">开通企业会员</p>
								<p class="vip-tag fs_12 flex_cen" v-else><img src="@/assets/imgs/icon/vip-icon.png" class="icon"
										alt="">年费企业会员</p>
							</div>
							<div class="expiration-time" v-if="COMPANY.company_lv">有效期至：{{ COMPANY.period_validity }}
							</div>
						</div>
					</div>
				</div>
				<div class="right flex_start flex_1">
					<div class="rule fs_14 color_999 btn" @click="handleDialog('ruleDialog')">
						CRC规则
					</div>
					<div class="padding_15 crc flex_1 link">
						<!-- <div class="padding_15 crc flex_1"> -->
						<div class="flex_c crc-box">
							<span class="fs_14 color_333 mr_10">企业CRC</span>
							<span class="crcnum" v-text="company.balance"></span>
						</div>
						<div class="mt_10 fs_12 info_color">
							<p>CRC可用于商城礼品兑换或平台企业服务</p>
						</div>
						<div>
							<!-- <el-button type="primary" class="fs_14 giveBtn" @click="give">转赠</el-button> -->
							<!-- <el-button type="primary" class="fs_14 dealBtn" @click="deal">交易</el-button> -->
						</div>
					</div>
					<div class="padding_15 flex_1 crc" style="padding-bottom:49px">
						<div class="flex_c crc-box">
							<span class="fs_14 color_333 mr_10">部门CRC</span>
							<span class="crcnum" v-text="company.detp_balance"></span>
						</div>
						<div class="mt_10 fs_12 info_color">
							<p>CRC可用于商城礼品兑换或平台部门服务</p>
						</div>
					</div>
				</div>
			</div>
			<div class="main">
				<div class="details">
					<el-tabs v-model="activeName">
						<el-tab-pane label="企业信息" name="info">
							<info ref="info" v-if="activeName == 'info'"></info>
						</el-tab-pane>
						<el-tab-pane label="企业同事" name="colleagues">
							<colleagues ref="colleagues" v-if="activeName == 'colleagues'"></colleagues>
						</el-tab-pane>
						<el-tab-pane label="企业CRC明细" name="comcrc">
							<company-crc ref="companyCrc" :type="'balances'" v-if="activeName == 'comcrc'"></company-crc>
						</el-tab-pane>
						<el-tab-pane label="部门CRC明细" name="depcrc">
							<company-crc ref="departmentCrc" :type="'dept_crc'" v-if="activeName == 'depcrc'"></company-crc>
						</el-tab-pane>
						<el-tab-pane label="开票信息" name="invoice">
							<invoice ref="invoice" v-if="activeName == 'invoice'"></invoice>
						</el-tab-pane>
						<el-tab-pane label="会员订单" name="viporder">
							<vip-order ref="viporder" v-if="activeName == 'viporder'"></vip-order>
						</el-tab-pane>
						<el-tab-pane label="推荐企业" name="enterprise">
							<enterprise ref="enterprise" v-if="activeName == 'enterprise'"></enterprise>
						</el-tab-pane>
					</el-tabs>
				</div>
			</div>
			<!-- CRC规则 -->
			<rule-dialog ref="ruleDialog"></rule-dialog>
			<!-- 转增 -->
			<give-dialog ref="giveDialog"></give-dialog>
			<!-- 开发中 -->
			<coding-dialog ref="codingDialog"></coding-dialog>

		</div>
	</div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex"
import ruleDialog from "@/components/rule-dialog"
import codingDialog from "@/components/coding-dialog"
import info from "./components/info"
import colleagues from "./components/colleagues"
import enterprise from "./components/enterprise"
import vipOrder from "./components/vip-order"
import companyCrc from "./components/company-crc"
import invoice from "./components/invoice"
import giveDialog from "./components/give-dialog"
import { l_getItem } from "@/common/cache"
export default {
	name: "account",
	components: {
		ruleDialog,
		codingDialog,
		info,
		colleagues,
		enterprise,
		vipOrder,
		companyCrc,
		invoice,
		giveDialog
	},
	data() {
		return {
			activeName: "info",
			COMPANY: {},
			company: {
				company_name: "", //企业名称
				creditcode: "", //征信代码
				staff_num_range: "", //人员规模
				address: "", //注册地址
				represent: "", //法人代表
				logo: "", //企业LOGO
				balance: "", //企业CRC
				credits: "", //部门CRC
				pics: [] //相册列表
			}
		}
	},
	computed: {
		...mapGetters({
			companyInfo: "getCompanyInfo"
		})
	},
	created() {
		this.COMPANY = l_getItem('COMPANY')
		this.activeName = this.$route.query.id || "info"
		this.getCompanyInfo()
	},
	watch: {
		$route(n) {
			this.activeName = n.query.id || "info"
		}
	},
	methods: {
		...mapMutations({
			setCompany: "set_companyInfo"
		}),

		getCompanyInfo() {
			this.$axios({
				url: "/ent/v3/usercenter/company/info",
				method: "GET",
				hideLoading: true
			}).then(res => {
				if (res.code === 200) {
					let companyInfo = { ...this.companyInfo, ...res.data.company }
					this.company = companyInfo
					setTimeout(() => {
						this.setCompany(companyInfo)
					}, 100)
				}
			})
		},
		give() {
			this.$refs.giveDialog.dialogVisible = true
			this.$refs.giveDialog.balance = this.company.balance
		},
		deal() {
			this.$refs.codingDialog.dialogVisible = true
		},
		handleDialog(dualog) {
			this.$refs[dualog].dialogVisible = true
		}
	}
}
</script>

<style lang="less" scoped>
/deep/.main {
	background: #F6F8FC;
}

.page {
	position: fixed;
	width: 100%;
	height: auto;
	top: 80px;
	left: 0;
}

.crc-info {
	height: 180px;
	background-color: #fff;
	position: relative;
	margin-top: 20px;
	border-radius: 2px;

	.left {
		width: 400px;
		padding: 0 20px;
		height: 180px;
		background: url("../../assets/imgs/company-bg.png") no-repeat;
		background-size: 100% 100%;
		color: #fff;
		position: relative;

		.chart-btn {
			position: absolute;
			border-radius: 2px;
			right: 20px;
			top: 15px;
			z-index: 3;
			width: 89px;
			height: 32px;
			border: 1px solid #ffffff;
			border-radius: 4px;
		}

		.logo {
			width: 80px;
			height: 80px;
			margin-right: 10px;
			background: #ffffff;
			border-radius: 50%;
			border: 2px solid #fff;
		}

		.auth {
			width: 22px;
			height: auto;
			margin-left: 5px;
			display: inline-block;
		}

		.vip-tag {
			// width: 106px;
			padding: 0 14px;
			height: 26px;
			background: linear-gradient(to right, #747474, #1a1a1a);
			color: #EAD7B2;
			border-radius: 4px;
			margin-top: 10px;

			.icon {
				width: 18px;
				height: auto;
				margin-right: 3px;
			}
		}

		.expiration-time {
			color: #EAD7B2;
			font-size: 12px;
			margin-bottom: -20px;
			margin-top: 4px;
		}
	}

	.right {
		position: relative;
		height: 100%;
		padding-right: 70px;

		.rule {
			position: absolute;
			width: 89px;
			height: 32px;
			line-height: 32px;
			border: 1px solid #1890FF;
			color: #1890FF;
			text-align: center;
			border-radius: 2px;
			top: 15px;
			right: 20px;
			z-index: 3;
			background: #fff;
		}

		.crc {
			padding-left: 65px;
			padding-top: 43px;

			.crc-box {
				align-items: flex-end;
			}

			.crcnum {
				font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
				color: #000;
				line-height: 28px;
				font-size: 24px;
			}
		}

		.link {
			position: relative;

			&::after {
				position: absolute;
				content: '';
				width: 1px;
				height: 100px;
				right: 0;
				top: 32px;
				background: #ededed;
			}
		}

		.giveBtn {
			background: #5AB0FF;
			border-color: #5AB0FF;
			width: 84px;
			height: 32px;
			margin-top: 16px;
		}

		.dealBtn {
			background: #A5D4FF;
			width: 84px;
			height: 32px;
			margin-top: 16px;
			border: none;
		}
	}
}

.main {
	padding: 2px 50px 60px;
	background-color: #fff;
	margin-top: 32px;

	.details {
		margin-top: 30px;
		position: relative;

		/deep/.el-tabs {
			.el-tabs__header {
				margin-bottom: 30px;
			}

			.el-tabs__active-bar {
				background-color: #1890ff;
			}

			.el-tabs__item {
				font-size: 16px;

				&.is-active {
					color: #1890ff;
				}
			}
		}

		.utils-btn {
			position: absolute;
			right: 0;
			top: 0;
			z-index: 9;

			.item:hover {
				color: #1890ff !important;

				.auth-icon {
					background: url(../../assets/imgs/icon/auth-icon1.png);
				}

				.qr-icon {
					background: url(../../assets/imgs/icon/qr-icon1.png);
				}
			}

			.icon {
				width: 24px;
				height: 24px;
				background-size: 100% 100% !important;

				&.auth-icon {
					background: url(../../assets/imgs/icon/auth-icon.png);
				}

				&.qr-icon {
					background: url(../../assets/imgs/icon/qr-icon.png);
				}
			}
		}
	}
}

/deep/.el-dialog__body {
	padding: 0;
}
</style>
