<!--
 * @Author: WeChat: TashiDelek668
 * @Date: 2021-01-21 10:04:34
 * @LastEditors: TashiDelek
 * @LastEditTime: 2021-01-21 10:29:43
 * @FilePath: \hr_admin_2.0\src\views\user\components\share-dialog.vue
-->
<template>
	<el-dialog v-model="dialogVisible" :lock-scroll="true" :close-on-click-modal="false" width="700px" top="20vh">
		<template v-slot:title>
			<div class="flex_c">
				<p class="fs_18 bold">邀请新同事</p>
			</div>
		</template>
		<div class="content">
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm">
				<div class="flex_c">
					<img src="../../../assets/imgs/icon/in-icon.png" class="in-icon" alt="">
					<div>
						<p class="fs_24 color_333">邀请链接已创建</p>
						<p class="fs_14 color_333 mt_5">您可以将链接分享至QQ、微信、钉钉等工作群，同事们通过此链接即可加入企业。</p>
					</div>
				</div><br><br>
				<div class="flex">
					<el-input v-model.trim="title" readonly class="fs_14 color_333 one"></el-input>
					<div class="fs_16 el-button color_333 btn" ref="copyTitle" @click="copyTitle($event)">复制邀请文案</div>
				</div>
				<div class="flex mt_20">
					<el-input v-model="link" readonly class="fs_14 color_333"></el-input>
					<div class="fs_16 color_333 el-button btn" ref="copyLink" @click="copyLink($event)">复制邀请链接</div>
				</div>

				<el-form-item label="" prop="mobile">
					<div class="flex mt_20">
						<el-input v-model.trim="ruleForm.mobile" type="number" placeholder="填写被邀请同事手机号码" class="fs_14 color_333"></el-input>
						<el-button class="fs_16 color_333 " @click="submit">发送短信邀请</el-button>
					</div>
				</el-form-item>
			</el-form>
			<div class="fs_14 color_999 center">该链接有效时长为{{ hour }}小时，请尽快发送给被邀请同事！</div>
		</div>
		<template v-slot:footer>

		</template>
	</el-dialog>
</template>

<script>
import clipboard from 'clipboard'
import { isPhone } from '@/common/validate'
export default {
	name: '',
	data() {
		var checkMobile = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('填写被邀请同事手机号码'))
			} else if (!isPhone(value)) {
				callback(new Error('请输入正确的手机号码'))
			} else {
				callback()
			}
		}
		return {
			dialogVisible: false,
			inviteId: '',
			title: '',
			link: '',
			loading: false,
			hour: 0,
			ruleForm: {
				mobile: ''
			},
			rules: {
				mobile: [
					{ validator: checkMobile, trigger: 'change' }
				]
			}
		}
	},

	methods: {
		getLink() {
			this.$axios({
				url: '/ent/v3/usercenter/company/members/invite_link',
				method: 'GET',
				hideLoading: true
			}).then(res=>{
				if(res.code === 200) {
					this.dialogVisible = true
					this.link = res.data.link
					this.title = res.data.content
					this.inviteId = res.data.id
					this.hour = res.data.hour
				}
			})
		},
		copyTitle(event) {
			this.handleClipboard(this.title, event)
		},
		copyLink(event) {
			this.handleClipboard(this.link, event)
		},
		handleClipboard(text, event) {
			let _clipboard = new clipboard(event.target, {
				text: () => text
			})
			_clipboard.on('success', () => {
				this.$message.success("复制成功")
				_clipboard.destroy()
			})
			_clipboard.on('error', () => {
				this.$message.warning("复制失败")
				_clipboard.destroy()
			})
			_clipboard.onClick(event)
		},
		submit() {
			this.$refs.ruleForm.validate((valid) => {
				if (valid) {
					this.loading = true
					this.$axios({
						url: `/ent/v3/usercenter/company/members/invite_link_send/${this.inviteId}`,
						method: 'POST',
						data: {
							mobile: this.ruleForm.mobile
						},
						hideLoading: true,
						shake: true
					}).then(res => {
						this.loading = false
						if(res.code === 200) {
							this.$message.success(res.msg)
							this.dialogVisible = false
						}
					})
				}
			})
		}
	}
}
</script>

<style lang='less' scoped>
/deep/.el-dialog__header {
	padding: 20px;
}
/deep/.el-dialog__body {
	padding: 0;
}
.content {
	border-top: 1px solid #ddd;
	// height: 168px;
	padding: 30px 30px 0;
	.in-icon {
		width: 46px;
		margin-right: 16px;
	}
	/deep/.el-input__inner {
		height: 44px;
		background: #f9f9f9;
		padding-left: 20px;
		font-size: 14px;
	}
	/deep/.el-button {
		width: 160px;
		height: 44px;
		line-height: 28px;
		margin-left: 20px;
		border-color: #ddd;
		padding: 9px 0;
		&:hover {
			border-color: #5ab0ff;
		}
	}
}
</style>