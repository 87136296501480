<template>
    <div>
        <el-dialog v-model="dialogVisible" :lock-scroll="true" :close-on-click-modal="false" width="460px" top="25vh">
            <template v-slot:title>
                <div class="flex_c">
                    <p class="fs_18 bold">提示</p>
                </div>
            </template>
            <div class="content">
                <div class="flex_cen">
                    <img src="../assets/imgs/coding.png" class="coding-img" alt="">
                </div>
                <p class="fs_16 color_333 mt_30 center">模块开发中，敬请期待！</p>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false
		}
	}
}
</script>

<style lang='less' scoped>
.coding-img {
    width: 199px;
    height: auto;
}
.content {
    border-top: 1px solid #ddd;
    padding: 30px 58px;
}
.mt_30 {
    margin-top: 33px;
}
</style>